body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/*Added for tooltip partly covered by the dropdown when disabled */
.slds-listbox__item {
	margin-top: 4px !important;
}

.quill {
	border: 1px solid #1689ee;
	border-radius: 4px;
}

.ql-editor {
	min-height: 200px !important;
}

.modal-header-text {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	/* number of lines to show */
	-webkit-box-orient: vertical;
	text-align: left;
	font-size: 14px;
}

.title-text {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	/* number of lines to show */
	-webkit-box-orient: vertical;
	padding: 5px;
}

[data-tooltip]:before {
	/* needed - do not touch */
	content: attr(data-tooltip);
	position: absolute;
	top: 0px;
	left: 0px;
	/* customizable */
	transition: all 0.15s ease;
	padding: 10px;
	color: #fff;
	width: 100% !important;
	height: 100% !important;
	opacity: 0;
	text-align: center;
}

[data-tooltip]:hover:before {
	opacity: 1;
	background-color: black;
	margin-top: 0px;
	margin-left: 0px;
	text-align: center;
}

[data-tooltip]:not([data-tooltip-persistent]):before {
	pointer-events: none;
}

.sucess-icn {
	--sds-c-icon-color-foreground-default: blue;
}

.btn-pagination:focus {
	box-shadow: none;
}

.wrapper {
	display: flex;
	min-height: 80px;
	width: 100%;
}

.container {
	align-self: center;
	display: flex;
	width: 100%;
}

.disableContainer {
	background-color: #ecebea;
	border-color: #c9c7c5;
	color: #3e3e3c;
	cursor: not-allowed;
	-user-select: none;
}

.box-container {
	border: 1px solid rgba(0, 0, 0, 0.125);
	margin-top: 5px;
	padding: 10px;
	border-radius: 4px;
}

.RichText {
	max-height: 90px;
	overflow: hidden;
}

.panel_header {
	display: flex;
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid #dddbda;
}

.header-title {
	font-size: 1rem;
	line-height: 1.25;
}

.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
	padding: 0px 3px !important;
}

.break-word{
	overflow-wrap: break-word
}

.editor-container{
	padding: 10px;
	max-height: 485px
}

.editor-title{
	font-size: 15px; 
	padding-bottom: 10px
}

.media-editor-title{
	font-size: 15px; 
	padding-bottom: 10px;
	margin-top: 10px
}

.card-without-img-container {
	margin-top: 5px;
	position: relative;
	display: flex;
	flex-direction: column;
	word-wrap: break-word;
	padding-left: 2px;
	padding-right: 2px;
}

.card-without-img-container-selected {
	background-color: #fff;
	background-clip: border-box;
	border: 3px solid #0070d2;
	min-height: 70px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card-without-img-container-unselected {
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0,0,0,.125);
	min-height: 70px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	padding: 5px;
}

.card-content {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	color: #080707;
	word-break: break-all
}

.card-content-container {
	display: grid;
	padding-left: 5px
}

.card-img-container-selected { 
	margin-left: 2px;
	margin-right: 2px;
	height: 215px;
	margin-top: 2px;
	position: relative;
	display: flex;
	flex-direction: column;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 3px solid #0070d2;
	border-radius: .25rem
}

.card-img-container-unselected { 
	margin-left: 2px;
	margin-right: 2px;
	min-height: 215px;
	margin-top: 2px;
	position: relative;
	display: flex;
	flex-direction: column;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0,0,0,.125);
	border-radius: .25rem
}

.pagging-action-btn {
	padding-top: 7px;
	padding-right: 5px; 
}

.limit-dropdown-container {
	border: 1px solid #DDDBDA;
	font-size: 12px;
	padding: 7px;
	margin-right: 20px;
	color: #0070D2;
	border-radius: 4px;
}

.main-container {
	background-color: white;
	border-bottom: 2px solid #5088EB
}

.home-container {
	padding: 20px;
	background: #b0c4df;
	text-align: center;
	margin-top: 200px
}
.home-txt-container {
	padding-top: 100px;
	padding-bottom: 100px;
}
